body{font-family: 'Roboto', sans-serif; padding: 0; margin:0; font-size: 16px;}
h1,h2,h3,h4,h5,h6{padding: 0; margin:0}
.login-form{width: 500px;}
.MuiBackdrop-root{z-index: 1!important;}
.proImgCon{display: flex!important;}
.proImgCon{max-width: 200px; display: flex;}
.proimg{width: 60px; height: 60px; border: 1px solid #ddd; padding: 5px; margin: 0 5px 5px 0;}
.proimg img{width: 100%; height: 100%; object-fit: cover;}
.proDes{max-width: 200px;}
.marTop50{margin-top: 50px;}
.dashboardLink h1{
    display: flex;
    height: calc(100vh - 100px);
    align-items: center;
    justify-content: center;
}
.search-product{display: flex; align-items: center; justify-content: flex-end; padding: 0; margin: 25px 0;}
.search-product li{list-style: none;}
.search-product li:first-child{margin-right: 15px;}